<template>
  <div>
    <v-navigation-drawer
        class="non-printable"
        app
        :width="navWidth"
        right
        temporary
        v-model="setting_dialog"
        @input="onNavInput"
    >
      <v-btn
          @click="closeSettings"
          depressed
      >Приховати налаштування
      </v-btn>

      <v-row>
        <v-col cols="12">
          <v-card tile elevation="0">
            <v-card-text class="pt-2">
              <v-row>
                <v-subheader class="success--text" style="border-bottom: 1px solid var(--v-success-base); width: 100%; height: 40px; padding-top: 6px">
                  Налаштування друку
                </v-subheader>
                <v-col cols="12" md="6">
                  <v-switch hide-details
                            color="grey darken-1"
                            v-model="document_setting.duplicate_on_one_page"
                            label="2 копії на одну сторінку"
                            class="mt-0"
                            @change="duplicateOnOnePageChange"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-switch hide-details
                            color="grey darken-1"
                            v-model="document_setting.duplicate_on_differance_page"
                            label="2 копії на різні сторінки"
                            class="mt-0"
                            @change="duplicateOnDifferancePageChange"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-switch hide-details
                            color="grey darken-1"
                            v-model="document_setting.show_signature"
                            label="Виводити підписи"
                            class="mt-0"
                  />
                </v-col>
                <v-col cols="12">
                  <v-textarea color="success"
                              label="Додатковий текст"
                              auto-grow
                              rows="2"
                              placeholder="Введіть бажаний текст для відображення у документі"
                              v-model="document_setting.additional_text"
                              hide-details
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <div class="page page-a4" style="height: auto !important; min-height: 215mm">
      <div class="document-print-form">
        <template v-if="document_setting.duplicate_on_one_page">
          <template v-for="copy in 2">
            <table class="document-table-form" :key="`copy-${copy}`" v-if="document_data.date">
              <caption class="mb-3">
                <div class="document-title title-underline">
                  {{
                    `Акт списання товарів №${document_data.number} від ${formatDate(document_data.date, 'DD MMMM YYYY')} р.`
                  }}
                </div>
                <div class="document-body">
                  <div class="document-body-line mb-2">
                    <div class="d-flex">
                      <div style="flex: 0 0 200px; font-weight: bold">Організація: </div>
                      <div style="flex: 1 1">
                        <div>{{ organization.short_name }}, {{ print_data.organization_details_for_document }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="document-body-line mb-2">
                    <div class="d-flex">
                      <div style="flex: 0 0 200px; font-weight: bold">Склад: </div>
                      <div style="flex: 1 1">
                        <div>{{ document_data.warehouse_name }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="document-body-line mb-2">
                    <div class="d-flex">
                      <div style="flex: 0 0 200px; font-weight: bold">Підстава: </div>
                      <div style="flex: 1 1">
                        <div>Підстава списання</div>
                      </div>
                    </div>
                  </div>
                  <div class="document-body-line mb-2">
                    <div class="d-flex">
                      <div style="flex: 0 0 200px; font-weight: bold">Рахунок списання: </div>
                      <div style="flex: 1 1">
                        <div>Підстава списання</div>
                      </div>
                    </div>
                  </div>
                  <div class="document-body-line mt-1" v-if="document_setting.additional_text">
                    {{ document_setting.additional_text }}
                  </div>
                </div>
              </caption>
              <thead>
              <tr>
                <th style="width: 50px">№</th>
                <th>Номенклатура/Послуга</th>
                <th style="width: 80px">Од.виміру</th>
                <th style="width: 100px">Ціна</th>
                <th style="width: 100px">К-сть</th>
                <th style="width: 120px">Сума</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, idx) in document_data.table" :key="idx">
                <td style="width: 50px; text-align: center">
                  {{ idx + 1 }}
                </td>
                <td>{{ item.nomenclature }}</td>
                <td style="width: 80px; text-align: center">
                  {{ item.unit_type_name }}
                </td>
                <td style="width: 100px; text-align: center">
                  {{ item.price }}
                </td>
                <td style="width: 100px; text-align: center">
                  {{ item.count }}
                </td>
                <td style="width: 120px; text-align: right">
                  {{ item.sum  | formatToFixed | formatNumber }}
                </td>
              </tr>
              <tr class="total">
                <td colspan="5" style="text-align: right; padding-top: 4px">Всього:</td>
                <td style="text-align: right; padding-top: 4px">
                  {{ document_data.total_sum | formatToFixed | formatNumber }}
                </td>
              </tr>
              <tr class="total">
                <td colspan="5" style="text-align: right">ПДВ:</td>
                <td style="text-align: right">
                  {{ document_data.total_tax | formatToFixed | formatNumber }}
                </td>
              </tr>
              <tr class="total">
                <td colspan="5" style="text-align: right">До оплати:</td>
                <td style="text-align: right">
                  {{ document_data.total_all | formatToFixed | formatNumber }}
                </td>
              </tr>
              <tr class="total">
                <td colspan="6" style="font-weight: normal !important;">
                  Всього найменувань {{document_data.table.length}}, на суму: {{ document_data.total_sum | formatToFixed | formatNumber }} грн.
                </td>
              </tr>
              <tr class="total">
                <td colspan="6">
                  {{ document_data.converted_number }}
                </td>
              </tr>
              <tr class="total" style="padding: 0 6px">
                <td colspan="6" style="border-bottom: 2px solid black !important; padding-left: 6px"></td>
              </tr>
              </tbody>
              <tfoot v-if="document_setting.show_signature">
              <tr class="pt-4">
                <td colspan="3" style="font-size: .8rem; padding: 20px 6px 3px;">
                  <div style="flex: 0 0 110px">
                    Від постачальника:
                  </div>
                  <div style="flex: 1; border-bottom: 1px solid black; margin-right: 60px; height: 19px">
                  </div>
                </td>
                <td colspan="3" style="font-size: .8rem; padding: 20px 6px 3px;">
                  <div style="flex: 0 0 100px">
                    Отримав(ла):
                  </div>
                  <div style="flex: 1; border-bottom: 1px solid black; height: 19px">
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="3" style="font-size: .8rem; padding: 0 6px 3px;">
                </td>
                <td colspan="3" style="font-size: .8rem; padding: 0 6px 3px;">
                  {{ getResponsePerson('organization', 'director', 'position') }} {{ getResponsePerson('organization', 'director', 'short_name') }}
                </td>
              </tr>
              </tfoot>
            </table>
          </template>
        </template>
        <template v-if="document_setting.duplicate_on_differance_page">
          <template v-for="copy in 2">
            <table class="document-table-form" :key="`copy-${copy}`" v-if="document_data.date">
              <caption class="mb-3">
                <div class="document-title title-underline">
                  {{
                    `Прибуткова накладна №${document_data.number} від ${formatDate(document_data.date, 'DD MMMM YYYY')} р.`
                  }}
                </div>
                <div class="document-body">
                  <div class="document-body-line mb-2">
                    <div class="d-flex">
                      <div style="flex: 0 0 120px; font-weight: bold">Виконавець: </div>
                      <div style="flex: 1 1">
                        <div style="font-weight: bold">{{ document_data.contractor_short_name }}</div>
                        <div>{{ (document_data.contractor_print_data || {}).print_data_for_document }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="document-body-line mb-2">
                    <div class="d-flex">
                      <div style="flex: 0 0 120px; font-weight: bold">Замовник: </div>
                      <div style="flex: 1 1">
                        <div style="font-weight: bold">{{ organization.short_name }}</div>
                        <div>{{ print_data.organization_details_for_document }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="document-body-line mb-2">
                    <div class="d-flex">
                      <div style="flex: 0 0 120px; font-weight: bold">Договір: </div>
                      <div style="flex: 1 1">
                        <div>{{ document_data.contract_name }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="document-body-line mb-2">
                    <div class="d-flex">
                      <div style="flex: 0 0 120px; font-weight: bold">Склад: </div>
                      <div style="flex: 1 1">
                        <div>{{ document_data.warehouse_name }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="document-body-line mt-1" v-if="document_setting.additional_text">
                    {{ document_setting.additional_text }}
                  </div>
                </div>
              </caption>
              <thead>
              <tr>
                <th style="width: 50px">№</th>
                <th>Номенклатура/Послуга</th>
                <th style="width: 80px">Од.виміру</th>
                <th style="width: 100px">Ціна</th>
                <th style="width: 100px">К-сть</th>
                <th style="width: 120px">Сума</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, idx) in document_data.table" :key="idx">
                <td style="width: 50px; text-align: center">
                  {{ idx + 1 }}
                </td>
                <td>{{ item.nomenclature }}</td>
                <td style="width: 80px; text-align: center">
                  {{ item.unit_type_name }}
                </td>
                <td style="width: 100px; text-align: center">
                  {{ item.price }}
                </td>
                <td style="width: 100px; text-align: center">
                  {{ item.count }}
                </td>
                <td style="width: 120px; text-align: right">
                  {{ item.sum  | formatToFixed | formatNumber }}
                </td>
              </tr>
              <tr class="total">
                <td colspan="5" style="text-align: right; padding-top: 4px">Всього:</td>
                <td style="text-align: right; padding-top: 4px">
                  {{ document_data.total_sum | formatToFixed | formatNumber }}
                </td>
              </tr>
              <tr class="total">
                <td colspan="5" style="text-align: right">ПДВ:</td>
                <td style="text-align: right">
                  {{ document_data.total_tax | formatToFixed | formatNumber }}
                </td>
              </tr>
              <tr class="total">
                <td colspan="5" style="text-align: right">До оплати:</td>
                <td style="text-align: right">
                  {{ document_data.total_all | formatToFixed | formatNumber }}
                </td>
              </tr>
              <tr class="total">
                <td colspan="6" style="font-weight: normal !important;">
                  Всього найменувань {{document_data.table.length}}, на суму: {{ document_data.total_sum | formatToFixed | formatNumber }} грн.
                </td>
              </tr>
              <tr class="total">
                <td colspan="6">
                  {{ document_data.converted_number }}
                </td>
              </tr>
              <tr class="total" style="padding: 0 6px">
                <td colspan="6" style="border-bottom: 2px solid black !important; padding-left: 6px"></td>
              </tr>
              </tbody>
              <tfoot v-if="document_setting.show_signature">
              <tr class="pt-4">
                <td colspan="3" style="font-size: .8rem; padding: 20px 6px 3px;">
                  <div style="flex: 0 0 110px">
                    Від постачальника:
                  </div>
                  <div style="flex: 1; border-bottom: 1px solid black; margin-right: 60px; height: 19px">
                  </div>
                </td>
                <td colspan="3" style="font-size: .8rem; padding: 20px 6px 3px;">
                  <div style="flex: 0 0 100px">
                    Отримав(ла):
                  </div>
                  <div style="flex: 1; border-bottom: 1px solid black; height: 19px">
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="3" style="font-size: .8rem; padding: 0 6px 3px;">
                </td>
                <td colspan="3" style="font-size: .8rem; padding: 0 6px 3px;">
                  {{ getResponsePerson('organization', 'director', 'position') }} {{ getResponsePerson('organization', 'director', 'short_name') }}
                </td>
              </tr>
              </tfoot>
            </table>
            <template v-if="copy === 1">
              <p class="document-separator" :key="`sep-${copy}`">&nbsp;</p>
            </template>
          </template>
        </template>
        <template v-if="!document_setting.duplicate_on_differance_page && !document_setting.duplicate_on_one_page">
          <table class="document-table-form" v-if="document_data.date">
            <caption class="mb-3">
              <div class="d-flex mb-6" style="font-size: 12px; text-align: left">
                <div style="flex: 0 0 60%"></div>
                <div style="flex: 1">
                  <div>ЗАТВЕРДЖУЮ</div>
                  <div>
                    {{ document_data.director_position }}
                  </div>
                  <div>{{ organization.full_name }}</div>
                  <div style="border-bottom: 1px solid black; height: 16px"></div>
                  <div>
                    {{ document_data.director_short_name }}
                  </div>
                </div>
                <div style="flex: 0 0 10%"></div>
              </div>
              <div class="document-title title-underline">
                {{
                  `Акт списання товарів  №${document_data.number} від ${formatDate(document_data.date, 'DD MMMM YYYY')} р.`
                }}
              </div>
              <div class="document-body">
                <div class="document-body-line mb-2">
                  <div class="d-flex">
                    <div style="flex: 0 0 120px; font-weight: bold">Організація: </div>
                    <div style="flex: 1 1">
                      <div>{{ document_data.organization_full_name }}, {{ print_data.organization_details_for_document }}</div>
                    </div>
                  </div>
                </div>
                <div class="document-body-line mb-2">
                  <div class="d-flex">
                    <div style="flex: 0 0 120px; font-weight: bold">Склад: </div>
                    <div style="flex: 1 1">
                      <div>{{ document_data.warehouse_name }}</div>
                    </div>
                  </div>
                </div>
                <div class="document-body-line mb-2">
                  <div class="d-flex">
                    <div style="flex: 0 0 120px; font-weight: bold">Підстава: </div>
                    <div style="flex: 1 1">
                      <div>{{ document_data.reason }}</div>
                    </div>
                  </div>
                </div>
                <div class="document-body-line mt-1" v-if="document_setting.additional_text">
                  {{ document_setting.additional_text }}
                </div>
              </div>
            </caption>
            <thead>
            <tr>
              <th rowspan="2" style="width: 40px; max-width: 40px">№</th>
              <th rowspan="2">Номенклатура</th>
              <th rowspan="2" style="width: 60px; max-width: 60px">Од. виміру</th>
              <th rowspan="2" style="width: 80px; max-width: 80px">Ціна</th>
              <th rowspan="2" style="width: 80px; max-width: 80px">К-сть</th>
              <th rowspan="1" colspan="2" style="width: 120px">Рахунок</th>
              <th rowspan="2" style="width: 110px; max-width: 110px">Сума</th>
            </tr>
            <tr>
              <th style="width: 60px">Дт</th>
              <th style="width: 60px">Кт</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, idx) in document_data.table" :key="idx">
              <td style="width: 50px; text-align: center">
                {{ idx + 1 }}
              </td>
              <td>{{ item.nomenclature_name }}</td>
              <td style="width: 80px; text-align: center">
                {{ item.unit_type_name }}
              </td>
              <td style="width: 100px; text-align: center">
                {{ item.price }}
              </td>
              <td style="width: 100px; text-align: center">
                {{ item.count }}
              </td>
              <td style="text-align: center">
                {{ item.debit_code }}
              </td>
              <td style="text-align: center">
                {{ item.credit_code }}
              </td>
              <td style="width: 120px; text-align: right">
                {{ item.sum  | formatToFixed | formatNumber }}
              </td>
            </tr>
            <tr class="total">
              <td colspan="7" style="font-weight: bold !important; padding-top: 8px; text-align: right; font-size: 1rem">
                Всього:
              </td>
              <td colspan="1" style="font-weight: bold !important; padding-top: 8px; text-align: right; font-size: 1rem">
                {{ document_data.total_sum | formatToFixed | formatNumber }}
              </td>
            </tr>
            <tr class="total">
              <td colspan="8" style="font-weight: normal !important; padding-top: 6px; padding-left: 0">
                Всього цим актом списано найменувань {{document_data.table.length}}, на суму: {{ document_data.total_sum | formatToFixed | formatNumber }} грн.
              </td>
            </tr>
            <tr class="total">
              <td colspan="8" style="font-weight: bold; padding-left: 0">
                {{ document_data.converted_number }}
              </td>
            </tr>
            <tr class="total" style="padding: 0 6px">
              <td colspan="8" style="border-bottom: 2px solid black !important; padding-left: 6px"></td>
            </tr>
            </tbody>
            <tfoot v-if="document_setting.show_signature">
            <tr class="pt-4" style="font-size: 12px;">
              <td colspan="8" style="padding-top: 8px">
                <div class="d-flex">
                  <div style="flex: 0 0 90px">Голова комісії: </div>
                  <div style="flex: 0 0 140px; border-bottom: 1px solid black; margin-left: 16px"></div>
                  <div style="flex: 0 0 90px; border-bottom: 1px solid black; margin-left: 16px"></div>
                  <div style="flex: 1; border-bottom: 1px solid black; margin-left: 16px"></div>
                </div>
                <div class="d-flex">
                  <div style="flex: 0 0 90px"></div>
                  <div style="flex: 0 0 140px; margin-left: 16px; text-align: center">посада</div>
                  <div style="flex: 0 0 90px; margin-left: 16px; text-align: center">підпис</div>
                  <div style="flex: 1; margin-left: 16px; text-align: center">прізвище, ім’я та по-батькові</div>
                </div>
              </td>
            </tr>
            <tr class="pt-4" style="font-size: 12px;">
              <td colspan="8" style="padding-top: 8px">
                <div class="d-flex">
                  <div style="flex: 0 0 90px">Члени комісії: </div>
                  <div style="flex: 0 0 140px; border-bottom: 1px solid black; margin-left: 16px"></div>
                  <div style="flex: 0 0 90px; border-bottom: 1px solid black; margin-left: 16px"></div>
                  <div style="flex: 1; border-bottom: 1px solid black; margin-left: 16px"></div>
                </div>
                <div class="d-flex">
                  <div style="flex: 0 0 90px"></div>
                  <div style="flex: 0 0 140px; margin-left: 16px; text-align: center">посада</div>
                  <div style="flex: 0 0 90px; margin-left: 16px; text-align: center">підпис</div>
                  <div style="flex: 1; margin-left: 16px; text-align: center">прізвище, ім’я та по-батькові</div>
                </div>
              </td>
            </tr>
            <tr class="pt-4" style="font-size: 12px;">
              <td colspan="8" style="padding-top: 16px">
                <div class="d-flex">
                  <div style="flex: 0 0 90px"> </div>
                  <div style="flex: 0 0 140px; border-bottom: 1px solid black; margin-left: 16px"></div>
                  <div style="flex: 0 0 90px; border-bottom: 1px solid black; margin-left: 16px"></div>
                  <div style="flex: 1; border-bottom: 1px solid black; margin-left: 16px"></div>
                </div>
                <div class="d-flex">
                  <div style="flex: 0 0 90px"></div>
                  <div style="flex: 0 0 140px; margin-left: 16px; text-align: center">посада</div>
                  <div style="flex: 0 0 90px; margin-left: 16px; text-align: center">підпис</div>
                  <div style="flex: 1; margin-left: 16px; text-align: center">прізвище, ім’я та по-батькові</div>
                </div>
              </td>
            </tr>
            <tr class="pt-4" style="font-size: 12px;">
              <td colspan="8" style="padding-top: 16px">
                <div class="d-flex">
                  <div style="flex: 0 0 90px"></div>
                  <div style="flex: 0 0 140px; border-bottom: 1px solid black; margin-left: 16px"></div>
                  <div style="flex: 0 0 90px; border-bottom: 1px solid black; margin-left: 16px"></div>
                  <div style="flex: 1; border-bottom: 1px solid black; margin-left: 16px"></div>
                </div>
                <div class="d-flex">
                  <div style="flex: 0 0 90px"></div>
                  <div style="flex: 0 0 140px; margin-left: 16px; text-align: center">посада</div>
                  <div style="flex: 0 0 90px; margin-left: 16px; text-align: center">підпис</div>
                  <div style="flex: 1; margin-left: 16px; text-align: center">прізвище, ім’я та по-батькові</div>
                </div>
              </td>
            </tr>
            </tfoot>
          </table>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import documentPrintAPI from "@/utils/axios/accounting/documents/warehouse_inventory_outcome"
import {formatDate} from "@/filters";

export default {
  name: "invoice_warehouse_income_v1",
  props: {
    organization: {
      type: Object,
      default() {
        return {}
      }
    },
    print_data: {
      type: Object,
      default() {
        return {}
      }
    },
    document_id: {
      type: Number,
      default: null
    },
    global_settings: {
      type: Boolean,
      default: false
    },
    modal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    navWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return '80%'
      }

      if (this.$vuetify.breakpoint.sm) {
        return '60%'
      }

      return '35%'
    }
  },
  data() {
    return {
      setting_dialog: this.global_settings,
      settings_watcher: null,
      document_setting: {
        additional_text: '',
        show_signature: true,
        duplicate_on_one_page: false,
        duplicate_on_differance_page: false
      },
      document_data: {}
    }
  },
  methods: {
    duplicateOnOnePageChange(payload) {
      if (payload) {
        this.document_setting.duplicate_on_differance_page = false
      }
    },
    duplicateOnDifferancePageChange(payload) {
      if (payload) {
        this.document_setting.duplicate_on_one_page = false
      }
    },
    watch_settings() {
      this.settings_watcher = this.$watch(
          'global_settings',
          {
            handler(payload) {
              this.setting_dialog = payload
            }
          }
      )
    },
    onNavInput(e) {
      if (!e) {
        this.$emit('closeSettings')
      }
    },
    closeSettings() {
      this.setting_dialog = false
      this.$emit('closeSettings')
    },
    formatDate,
    getResponsePerson(table, category, field='short_name') {
      if (table === 'organization') {
        return ((this.print_data?.response_people || []).find(i => i.category === category) || {})?.[field] || ''
      } else {
        return (this.document_data?.contractor_print_data || {})?.response_persons?.[category]?.[field] || ''
      }
    },
  },
  watch: {
    modal: {
      immediate: true,
      handler(payload) {
        this.document_setting.additional_text = ''

        if (payload) {
          documentPrintAPI.get_document_for_print(this.document_id)
              .then(response => response.data)
              .then(data => this.document_data = data)
        } else {
          this.document_data = {}
        }

      }
    }
  },
  created() {
    this.watch_settings()
  },
  beforeDestroy() {
    if (this.settings_watcher) {
      this.settings_watcher()
    }
  }
}
</script>

<style scoped lang="scss">
.document-print-form {
  width: 100%;
  font-family: Arial, Calibri, serif;
  padding: 4px 12px;

  .document-table-form {
    width: 100%;
    border-collapse: collapse;

    thead {
      tr {
        border: 1px solid grey;

        th {
          border: 1px solid grey;
          padding: 2px 6px;
          font-size: .8rem;
          font-weight: bold;
          line-height: 1.05rem;
        }
      }
    }

    tbody {
      tr {
        border: 1px solid grey;

        td {
          border: 1px solid grey;
          padding: 2px 6px;
          font-size: .77rem;
          line-height: .94rem;
        }
      }

      .without-border {
        border: none !important;

        td {
          border: none !important;
        }
      }

      .total {
        border: none !important;

        td {
          font-family: "Roboto", sans-serif;
          font-weight: bold;
          font-size: .83rem;
          border: none !important;
        }
      }
    }
  }

  .document-title {
    font-size: 1.2rem;
    font-weight: 600;
    text-align: left;
    line-height: 1.3rem;
  }

  .title-underline {
    border-bottom: 2px solid;
  }

  .text-ident {
    text-indent: 30px;
  }

  .document-body {
    font-size: .8rem;
    text-align: justify;
    margin-top: 12px;
    font-weight: 500;
  }
}
</style>